// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.samseg_whole_brain_measurements_widget {
  display: flex;
  justify-content: space-between;
  flex: 4 1;
  padding: 10px;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 100%;
}
.samseg_whole_brain_measurements_widget .left, .samseg_whole_brain_measurements_widget .right {
  justify-content: space-between;
  font-size: 12px;
}
.samseg_whole_brain_measurements_widget .left .icon, .samseg_whole_brain_measurements_widget .right .icon {
  font-size: 25px;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}
.samseg_whole_brain_measurements_widget .left .title, .samseg_whole_brain_measurements_widget .right .title {
  font-weight: bold;
  font-size: 14px;
  color: rgb(160, 160, 160);
}`, "",{"version":3,"sources":["webpack://./src/components/freesurfer/widget/samseg_whole_brain_measurements_widget.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,SAAA;EACA,aAAA;EAEA,sDAAA;EACA,mBAAA;EACA,YAAA;AACJ;AACI;EACI,8BAAA;EACA,eAAA;AACR;AACQ;EACI,eAAA;EACA,YAAA;EACA,kBAAA;EACA,oBAAA;AACZ;AAEQ;EACI,iBAAA;EACA,eAAA;EACA,yBAAA;AAAZ","sourcesContent":[".samseg_whole_brain_measurements_widget{\n    display: flex;\n    justify-content: space-between;\n    flex: 4;\n    padding: 10px;\n    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);\n    border-radius: 10px;\n    height: 100%;\n\n    .left, .right {\n        justify-content: space-between;\n        font-size: 12px;\n\n        .icon {\n            font-size: 25px;\n            padding: 5px;\n            border-radius: 5px;\n            align-self: flex-end;\n        }\n\n        .title {\n            font-weight: bold;\n            font-size: 14px;\n            color: rgb(160, 160, 160);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
