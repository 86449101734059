// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-left: 25%;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64vh;
    height: 64vh;
    margin: 8vh;
    border-radius: 50%;
    border: 10px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 5s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/css/loading.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,cAAc;IACd,WAAW;IACX,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,uBAAuB;IACvB,+CAA+C;IAC/C,2CAA2C;AAC/C;AACA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".lds-dual-ring {\n    display: inline-block;\n    width: 80px;\n    height: 80px;\n    margin-left: 25%;\n}\n.lds-dual-ring:after {\n    content: \" \";\n    display: block;\n    width: 64vh;\n    height: 64vh;\n    margin: 8vh;\n    border-radius: 50%;\n    border: 10px solid #000;\n    border-color: #000 transparent #000 transparent;\n    animation: lds-dual-ring 5s linear infinite;\n}\n@keyframes lds-dual-ring {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
