// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  border: 1px solid #ccc;
  display: flex;
  flex: 1 1;
  align-self: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
  width: auto;
  height: auto;
  padding: 10px 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/freesurfer/datatable/aseg.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,aAAA;EACA,SAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,4EAAA;EACA,mBAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;AAAJ","sourcesContent":["img{\n    border: 1px solid #ccc;\n    display: flex;\n    flex: 1;\n    align-self: center;\n    justify-content: center;\n    align-items: center;\n    background-color: white;\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n    margin-bottom: 25px;\n    //width: 100%;\n    width: auto;\n    height: auto;\n    padding: 10px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
