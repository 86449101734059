// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datatable {
  height: 630px;
}
.datatable .datatableTitle {
  width: 100%;
  font-size: 24px;
  color: gray;
  display: flex;
}
.datatable .datagrid {
  text-align-all: center;
}`, "",{"version":3,"sources":["webpack://./src/components/freesurfer/datatable/reconallvolumedatatable.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EACI,WAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;AAER;AACI;EACI,sBAAA;AACR","sourcesContent":[".datatable {\n    height: 630px;\n    .datatableTitle{\n        width: 100%;\n        font-size: 24px;\n        color: gray;\n        display: flex;\n\n    }\n    .datagrid{\n        text-align-all: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
