// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
    display: flex;
    flex-direction: column;
    gap: 8px; /* adjust as needed for spacing */
    width: 90%;
    margin-top: 8px;
    margin-left: 8px;
}

.button-container button {
    width: 100%; /* make buttons stretch to fill the container */
    margin-bottom: 8px; /* space between buttons */
}

/* Remove the margin-bottom from the last button */
.button-container button:last-child {
    margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/recon_all.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ,EAAE,iCAAiC;IAC3C,UAAU;IACV,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW,EAAE,+CAA+C;IAC5D,kBAAkB,EAAE,0BAA0B;AAClD;;AAEA,kDAAkD;AAClD;IACI,gBAAgB;AACpB","sourcesContent":[".button-container {\n    display: flex;\n    flex-direction: column;\n    gap: 8px; /* adjust as needed for spacing */\n    width: 90%;\n    margin-top: 8px;\n    margin-left: 8px;\n}\n\n.button-container button {\n    width: 100%; /* make buttons stretch to fill the container */\n    margin-bottom: 8px; /* space between buttons */\n}\n\n/* Remove the margin-bottom from the last button */\n.button-container button:last-child {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
