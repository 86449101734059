// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datatable {
  height: 600px;
  padding: 20px;
}
.datatable .columns {
  align-items: center;
}
.datatable .datatableTitle {
  width: 100%;
  font-size: 24px;
  color: gray;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.datatable .datatableTitle .link {
  text-decoration: none;
  color: green;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.datatable .datagrid {
  grid-row: red;
  text-decoration-color: red;
  text-align-all: center;
}
.datatable .cellWithImg {
  display: flex;
  align-items: center;
}
.datatable .cellWithImg .cellImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}
.datatable .buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/freesurfer/datatable/samsegdatatable.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;AACJ;AAAI;EACI,mBAAA;AAER;AAAI;EACI,WAAA;EACA,eAAA;EACA,WAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAER;AADQ;EACI,qBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAGZ;AAAI;EACI,aAAA;EACA,0BAAA;EACA,sBAAA;AAER;AAAI;EACI,aAAA;EACA,mBAAA;AAER;AAAQ;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;AAEZ;AACI;EACI,gBAAA;EACA,aAAA;EACA,uBAAA;AACR","sourcesContent":[".datatable {\n    height: 600px;\n    padding: 20px;\n    .columns {\n        align-items: center;\n    }\n    .datatableTitle {\n        width: 100%;\n        font-size: 24px;\n        color: gray;\n        margin-bottom: 10px;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        .link {\n            text-decoration: none;\n            color: green;\n            font-size: 16px;\n            font-weight: 400;\n            border: 1px solid green;\n            padding: 5px;\n            border-radius: 5px;\n            cursor: pointer;\n        }\n    }\n    .datagrid {\n        grid-row: red;\n        text-decoration-color: red;\n        text-align-all: center;\n    }\n    .cellWithImg {\n        display: flex;\n        align-items: center;\n\n        .cellImg {\n            width: 32px;\n            height: 32px;\n            border-radius: 50%;\n            object-fit: cover;\n            margin-right: 20px;\n        }\n    }\n    .buttonContainer {\n        margin-top: 20px;  // Adjust the margin to give more space\n        display: flex;\n        justify-content: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
